export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'DASHBOARD',
        to: '/dashboard',
        route: '/dashboard',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'BERITA',
        to: '/news',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'RUBRIK',
        to: '/rubrik',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'INSIDE TALK',
        to: '/insidetalk',
        icon: 'cil-folder-open',
      },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'SURAT TUGAS',
      //   route: '/surattugas',
      //   icon: 'cil-file',
      //   items: [
      //     {
      //       name: 'Dalam Provinsi',
      //       to: '/surattugas',
      //       icon: 'cil-check'
      //     },
      //     {
      //       name: 'Luar Provinsi',
      //       to: '/surattugas',
      //       icon: 'cil-check'
      //     },
      //     {
      //       name: 'Luar Negeri',
      //       to: '/surattugas',
      //       icon: 'cil-check'
      //     }         
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'PERJADIN',
      //   route: '/perjadin/laporandalamprovinsi',
      //   icon: 'cil-list',
      //   items: [
      //     {
      //       name: 'Laporan Dalam Provinsi',
      //       to: '/perjadin/laporandalamprovinsi',
      //       icon: 'cil-check'
      //     },
      //     {
      //       name: 'Laporan Luar Provinsi',
      //       to: '/perjadin/laporanluarprovinsi',
      //       icon: 'cil-check'
      //     },
      //     {
      //       name: 'Laporan Luar Negeri',
      //       to: '/perjadin/laporanluarnegeri',
      //       icon: 'cil-check'
      //     }            
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'PESERTA',
      //   to: '/peserta',
      //   icon: 'cil-people',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'AKUN SAKTI',
      //   to: '/upt',
      //   icon: 'cil-laptop',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'SIKRONISASI',
      //   to: '/anggaran',
      //   icon: 'cil-folder-open',
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'LAPORAN',
      //   route: '/laporan',
      //   icon: 'cil-file',
      //   items: [
      //     {
      //       name: 'Laporan Dalam Provinsi',
      //       to: '/perjadin/laporandalamprovinsi',
      //       icon: 'cil-check'
      //     },
      //     {
      //       name: 'Laporan Luar Provinsi',
      //       to: '/perjadin/laporanluarprovinsi',
      //       icon: 'cil-check'
      //     },
      //     {
      //       name: 'Laporan Luar Negeri',
      //       to: '/perjadin/laporanluarnegeri',
      //       icon: 'cil-check'
      //     }         
      //   ]
      // },
      
      
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'KONFIGURASI',
      //   route: '/setting',
      //   icon: 'cil-settings',
      //   items: [
      //     {
      //       name: 'Profilku',
      //       to: '/profilku',
      //       icon: 'cil-check'
      //     },
      //     {
      //       name: 'Ganti Password',
      //       to: '/gantipassword',
      //       icon: 'cil-check'
      //     }         
      //   ]
      // },    
      
      {
        _name: 'CSidebarNavItem',
        name: 'LOGOUT',
        to: '/logout',
        icon: 'cil-arrow-right'
      },
    ]
  }
]