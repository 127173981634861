<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img :src="require('../assets/images/favicon.jpeg')" class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem data-toggle="modal"> Hai, {{ nickname }} </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem to="/profilku"> <CIcon name="cil-user" /> Profilku </CDropdownItem>
    <CDropdownItem to="/gantipassword"> <CIcon name="cil-settings" /> Password </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem v-on:click="logoutNow" data-toggle="modal">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
let user = JSON.parse(localStorage.getItem("user"));
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
      nickname: user.name,
    };
  },
  methods: {
    logoutNow: function () {
      localStorage.setItem("user", null);
      localStorage.setItem("jwt", null);
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>