<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => {$store.commit('set', ['sidebarShow', value]); isShowing= !isShowing}"
  >
    <CSidebarBrand class="d-md-down-none"  :show="show" to="/" style="font-size:20px;">
      <span style="font-size:20px;"  v-if="!isShowing"><b>SIP</b></span><span v-if="isShowing"><b>INSIDEPONTIANAK</b></span>
    </CSidebarBrand>

    <li class="c-sidebar-nav-item" style="border-bottom: 1px solid #ddd;list-style-type: none;" data-v-afcd1a82=""><a href="#" target="_self" class="c-sidebar-nav-link"><!----><!----> <strong>{{ level }}</strong> <!----></a></li>
    <CRenderFunction flat :content-to-render="nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize]);isShowing= !isShowing"
    />
    
  </CSidebar>
</template>

<script>
let user = JSON.parse(localStorage.getItem('user'));
let level = user.level;
// alert(JSON.stringify(user));

import nav2 from './_nav';
import nav3 from './_nav2';
import nav4 from './_nav3';
import navAdmin from './_navadmin';
// let nav;

// if(level=='1'){
//   nav = nav2;
// }
// else{
//   nav = nav3;
// }
export default {
  name: 'TheSidebar',
  mounted(){
      let us = JSON.parse(this.$store.state.users);
      // alert(us.name);
      if(us.level=='admin')
      { 
        this.nav= navAdmin;
        this.level = us.level.toString().toUpperCase();
      }
      else if(us.level=='editor')
      {
          this.nav= nav2;
        this.level = us.level.toString().toUpperCase();
      }
      else 
      {  
        this.nav= nav3;
        this.level = us.level.toString().toUpperCase();
      }
              
  },
  data() {
    return {
      nav:[],
      isShowing:true,
      level : "EDITOR"
    };
  },
  // nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
